<template>
	<main>
		<section id="Rarity">
			<h1 data-aos="fade-in">Rarity</h1>
			<p>
				Each Warrior is categorized in terms of geographical location, strength, and
				agility.
			</p>

			<div class="rar_wmo flex">
				<div class="indiv" data-aos="fade-up">
					<div class="img">
						<img src="../assets/60.svg" alt="" />
					</div>
					<h2>Geographical Location</h2>
					<p>
						The warriors exist within tundra-like landscape, Africa, and Serbia.
					</p>
				</div>
				<div class="indiv" data-aos="fade-up" data-aos-delay="300">
					<div class="img">
						<img src="../assets/77.svg" alt="" />
					</div>
					<h2>Strength</h2>
					<p>
						Standing at 9-11 feet tall, each warrior posseses royal features making
						them immune to physical attack by outsiders.
					</p>
				</div>
				<div class="indiv" data-aos="fade-up" data-aos-delay="600">
					<div class="img">
						<img src="../assets/79.svg" alt="" />
					</div>
					<h2>Agility</h2>
					<p>
						The warriors are categorized by how fast they can move and how much power
						they wield in the tribe they represent.
					</p>
				</div>
			</div>
		</section>
	</main>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
export default {
	created() {
		AOS.init({
			duration: 1000,
			easing: "ease-in-out",
		});
	}
};
</script>

<style lang="scss" scoped>
main {
	background-image: url("../assets/10.svg"), url("../assets/9.svg");
	background-size: cover;
	background-position: right 10%, right 0%;
	padding: 40px 0px;
	@media (max-width: 700px) {
		background-image: none;
	}
	section {
		text-align: center;
		h1 {
			font-size: 2.5em;
			margin: 20px 0px;
			@media (max-width: 600px) {
				margin: 0px 0px;
			}
		}
		p {
			margin: 30px auto;
			color: #606060;
			@media (max-width: 600px) {
				margin: 15px auto;
			}
		}
		.rar_wmo {
			padding: 30px 0px;
			@media (max-width: 600px) {
				flex-direction: column;
			}
			.indiv{
				color: #42464c;
				width: 30%;
				@media (max-width: 600px) {
					margin: 20px 0px;
					width: 100%;
				}
				h2 {
					font-family: "Grotesk bold";

					margin: 10px 0px;
				}
				p {
					margin: 5px auto;
				}
			}
		}
	}
}
</style>
