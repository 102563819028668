<template>
  <main>
      <section class="flex">
          <div class="tr_wmo">
              <p>The Tribe &copy;</p>
          </div>
          <div class="social flex">
          <div class="sc_item">
              <a href="">
                  <img src="../assets/opensea.svg" alt="">
              </a>
          </div>
          <div class="sc_item">
              <a  target="_blank" href="https://twitter.com/warriormammoth">
                  <img src="../assets/logos_twitter.svg" alt="">
              </a>
          </div>
          <div class="sc_item">
              <a target="_blank" href="https://discord.gg/cFgkTf3989">
                  <img src="../assets/logos_discord-icon.svg" alt="">
              </a>
          </div>
      </div>
      </section>
  </main>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
main{
    padding: 30px 0px;
    color: white;
    background: #060607;

}
.social{
    width:10%;
    @media (max-width:700px) {
        width: 40%;
    }
 }
</style>