import { pinJSONToIPFS } from "./pinata";
import contractABI from '../../contract-abi.json';

require("dotenv").config();

const alchemyKey = process.env.VUE_APP_ALCHEMY_KEY;
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);

//const contractAddress = '0x644bcc992afc46505b25623a551489fda7dd4572';
const contractAddress = '0x73c3cA4610dBe505FdeDCE01D811D81De19a1c6e';

//live contract https://etherscan.io/address/0x73c3cA4610dBe505FdeDCE01D811D81De19a1c6e

export const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const obj = {
          status: "👆🏽 Write a message in the text-field above.",
          address: addressArray[0],
          ethereumInWindow: true,
        };
        return obj;
      } catch (err) {
        return {
          address: "",
          status: "😥 " + err.message,
          ethereumInWindow: true,
        };
      }
    } else {
      return {
        address: "",
        ethereumInWindow: false,
        status: (
        <template>
          <span>
            <p>
              {" "}
              🦊{" "}
              <a target="_blank" href={`https://metamask.io/download.html`}>
                You must install Metamask, a virtual Ethereum wallet, in your
                browser.
              </a>
            </p>
          </span>
          </template>
        ),
      };
    }
};

export const getCurrentWalletConnected = async () => {
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_accounts",
        });
        if (addressArray.length > 0) {
          return {
            address: addressArray[0],
            status: "👆🏽 Write a message in the text-field above.",
            ethereumInWindow: true,
          };
        } else {
          return {
            address: "",
            status: "🦊 Connect to Metamask using the top right button.",
            ethereumInWindow: true,
          };
        }
      } catch (err) {
        return {
          address: "",
          status: "😥 " + err.message,
          ethereumInWindow: true,
        };
      }
    } else {
      return {
        address: "",
        status: (
          <span>
            <p>
              {" "}
              🦊{" "}
              <a target="_blank" href={`https://metamask.io/download.html`}>
                You must install Metamask, a virtual Ethereum wallet, in your
                browser.
              </a>
            </p>
          </span>
        ),
        ethereumInWindow: false,
      };
    }
};

// async function loadContract() {
//     return new web3.eth.Contract(contractABI, contractAddress);
// }

export const mintNFT = async (url, name, description, amount) => {
  if (url.trim() == "" || name.trim() == "" || description.trim() == "") {
    return {
      success: false,
      status: "❗Please make sure all fields are completed before minting.",
    };
  }

  //make metadata
  const metadata = new Object();
  metadata.name = name;
  metadata.image = url;
  metadata.description = description;
  metadata.amount = amount;
  console.log("amount", amount)
  

  const pinataResponse = await pinJSONToIPFS(metadata);
  if (!pinataResponse.success) {
    return {
      success: false,
      status: "😢 Something went wrong while uploading your tokenURI.",
    };
  }
  // const tokenURI = pinataResponse.pinataUrl;

  const contract = await new web3.eth.Contract(contractABI, contractAddress);
const nonce = await web3.eth.getTransactionCount(window.ethereum.selectedAddress, 'latest');
const price = await contract.methods.getPrice().call();
  console.log(contract, ['contract']);
  console.log(window.ethereum.selectedAddress, ['eth'])
    
// let txTotal = 1 * price
let txTotal = amount * price

const hexString = txTotal.toString(16);
  window.contract = contract;

  const transactionParameters ={
  'from': window.ethereum.selectedAddress,
  'to': contractAddress,
  'nonce': nonce.toString(),
  'value': hexString,
  'data': contract.methods.mint(amount).encodeABI()
}

  try {
    const txHash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [transactionParameters],
    });
    console.log(txHash, ['transactionHash'])
    console.log(txTotal)
    return {
      success: true,
      status:
        "✅ Check out your transaction on Etherscan: https://mainnet.etherscan.io/tx/" +
        txHash,
    };
  } catch (error) {
    return {
      success: false,
      status: "😥 Something went wrong: " + error.message,
    };
  }
};