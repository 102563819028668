<template>
  <div style="padding: 1rem 0">
      You must <a style="text-decoration: underline" v-bind:href="link" target="_blank">install Metamask</a>, a virtual Ethereum wallet, in your browser<br/>
      NB: Double click to Mint on Mobile
  </div>
</template>
<script>
export default {
  name: "message",
  props: ['link'],
  data() {
    return {
    
    }
  },
};
</script>
