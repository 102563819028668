<template>
  <main>
      <section id="Intro" class="flex">
          <div class="off">
              <h3>INTRO</h3>

              <h1>Story Mode</h1>
          </div>
          <div class="img">
              <img  data-aos="fade-right"  data-aos-duration="1500"  data-aos-easing="ease-in" src="../assets/Group82.svg" alt="">
          </div>
          <div class="st_wmo">
              <h3>INTRO</h3>

              <h1>Story Mode</h1>

              <p>
                   Almost 20,000 years ago, the Warrior Mammoth was attacked by a group of hunting tribesmen.
                    An intense battle was fought which led to the extinction of the Warrior Mammoth. 
              </p>

             <p>
                 The Warrior Mammoth who was King of the Cold, with long coarse hairs, standing at 11 feet tall with an enormous tusk, gave one last heart wrenching
                  cry as a spear pierced through it’s heart and it fell to the ground with a loud thud.
             </p>

             <p>
                 As the Warrior Mammoth died out a thunder followed, the snow-covered mountain slopes gave away in a giant avalanche. 
                 The avalanche covered the body of the dying Warrior Mammoth, and it froze. The body remained in a frozen state for many years, till it was discovered by a group of humans 20,000 years later.
             </p>
            
             <p>
                 This time it was not a hunting tribe, but a group of NFT digital artists, armed with ancient creativity & future reign with the aim of regenerating 3,333 Warrior 
                 Mammoths in other to preserve the existential spirit of this extinct warrior.
             </p>
          </div>
      </section>
  </main>
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css'; 
export default {
 created(){
     AOS.init({

     });
 }
}
</script>

<style lang="scss" scoped>
main{
    padding: 30px 0px;
    background: linear-gradient(293.53deg, #0B0624 -0.48%, #514E62 100%);
    color: white;
    .off{
        display: none;
    }
    .img{
        width:53%;
        img{
            width:100%;
        }
    }
    .st_wmo{
         width:50%;

        h3{
            font-weight: normal;
        }
        h1{
            font-size:2.5em;
            margin:15px 0px;
        }
        p{
            margin:25px 0px;
        }
    }
}
@media (max-width:600px){
    main{
        padding:50px 0px;
        .off{
            display: block;
            text-align: center;
            h3{
                font-size: 0.9em;
                margin:10px 0px;
            }
        }
        .flex{
            flex-direction: column;
        }
        .img{
            width: 100%;
        }
        .st_wmo{
         width:100%;
        
        h3,h1{
            display: none;
        }
        p{
            margin:25px 0px;
        }
    }
    }
    
}
</style>