<template>
       <div class="wrap">
      <nav>
          <ul>
          <li>
              <a href="">Home</a>
          </li>
          <li>
              <a href="#Intro">Intro</a>
          </li>
          <li>
              <a href="#Rarity">Rarity</a>
          </li>
          <li>
              <a href="#wearables">Wearables</a>
          </li>
          <li>
              <a href="#faqs">FAQs</a>
          </li>
          <li>
              <a href="#roadmap">Roadmap</a>
          </li>
          <li>
              <a href="#creators">The Creators</a>
          </li>
      </ul>
      </nav>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
 .wrap{
     background: white; 
     position: fixed;
     left: 0px;
     right: 0px;
     top: 0px;
     color: black;
     nav{
         margin-left:20px;
         margin-top:50px;
         padding:20px 0px;
         ul{
             li{
                 margin: 20px 0px;
             }
         }
     }
 }
</style>