<template>
	<main>
		<section>
				<nav class="flex animate__animated animate__fadeIn animate__slow">
					<ul class="flex">
						<li>
							<a href="">Home</a>
						</li>
						<li>
							<a href="#Intro">Intro</a>
						</li>
						<li>
							<a href="#Rarity">Rarity</a>
						</li>
						<li>
							<a href="#wearables">Wearables</a>
						</li>
						<li>
							<a href="#faqs">FAQs</a>
						</li>
						<li>
							<a href="#roadmap">Roadmap</a>
						</li>
						<li>
							<a href="#creators">The Creators</a>
						</li>
					</ul>
					<div class="social flex">
						<div class="sc_item">
							<a target="_blank" href="https://opensea.io/collection/warrior-mammoths">
								<img src="../assets/opensea.svg" alt="" />
							</a>
	
						</div>
						<div class="sc_item">
							<a target="_blank" href="https://twitter.com/warriormammoth">
								<img src="../assets/logos_twitter.svg" alt="" />
							</a>
						</div>
						<div class="sc_item">
							<a target="_blank" href="https://discord.gg/cFgkTf3989">
								<img src="../assets/logos_discord-icon.svg" alt="" />
							</a>
						</div>
					</div>

					<div @click="openMenu" class="menu_btn">
						<img src="../assets/Menu.svg" alt="" />
					</div>
				</nav>
		</section>
	</main>
</template>

<script>
export default {
	name: "section",
	methods: {
		openMenu() {
			this.$emit("openMenu");
		}
	}
};
</script>

<style lang="scss" scoped>
main{
    background-color: rgb(255, 255, 255);
    position: fixed;
    z-index: 30;
		top: 0px;
        left: 0px;
        right: 0px;
        padding: 20px 0px;
}
	section {
		margin: 0 auto;
		li {
			margin: 0px 25px;

			a {
				color: #060607;
			}
		}
		.menu_btn {
			display: none;
		}
		.social {
			width: 12%;
		}
	}
@media (max-width: 1024px) {
	section {
		nav {
			ul {
				display: none;
				li,
				.sc_item {
					margin: 0 20px;
				}
			}
		}
	}
}
@media (max-width: 760px) {
	section {
		nav {
			.menu_btn {
				display: block;
			}
		}
		.social {
			width: 35%;
		}
	}
}
</style>
