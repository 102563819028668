<template>
  <main>
    <section id="creators">
      <h3>THE CREATORS</h3>
      <h1 data-aos="flip-up">Meet Us</h1>
      <p data-aos="fade-in" data-aos-duration="300">
        The team comprises of 3 intellectual NFT digital creators, a passionate
        designer and a brilliant developer who share great interest in the
        endless possibilities the NFT space has to offer and also understand the
        value of creating a body of work that would last for ages to come.
      </p>

      <div class="cre_wmo">
        <div class="first">
          <div class="cc_de">
            <div class="img">
              <img src="../assets/x.png" alt="" />
            </div>
            <div class="id" >

              <h2 class="name">Mammoth Temisan X </h2>    
            <p class="role">NFT Degen & Creative Director</p>
            <div class="sc_item" data-v-29e8c3c6=""><a target="_blank" href="https://twitter.com/TemisanX" data-v-29e8c3c6="">Twitter:       <img src="/img/logos_twitter.911e3d83.svg" alt="" data-v-29e8c3c6=""></a></div>
            </div>
          </div>
          <div class="cc_de">
            
            <div class="img">
              <img src="../assets/molo.svg" alt="" />
            </div>
            <div class="id">
              <h2 class="name">Mammoth Molo</h2>
              <p class="role">MW-0 & Co-creator</p>
                          <div class="sc_item" data-v-29e8c3c6=""><a target="_blank" href="https://twitter.com/mololuwa_O" data-v-29e8c3c6="">Twitter:       <img src="/img/logos_twitter.911e3d83.svg" alt="" data-v-29e8c3c6=""></a></div>

            </div>
          </div>
          <div class="cc_de">
            <div class="img">
              <img src="../assets/toni.png" alt="" />
            </div>
            <div class="id">
              <h2 class="name">Mammoth Toni</h2>
              <p class="role">Community Lead & Advisor</p>
            </div>
          </div>
        </div>
        <div class="first">
          <div class="cc_de">
            <div class="img">
              <img src="../assets/zosi.svg" alt="" />
            </div>
            <div class="id">
              <h2 class="name">Mammoth Zosi</h2>
              <p class="role">Artist & Design Rebel</p>
            </div>
          </div>
          <div class="cc_de">
            <div class="img">
              <img src="../assets/572.png" alt="" />
            </div>
            <div class="id">
              <h2 class="name">Mammoth Jesse</h2>
              <p class="role">Developer</p>
               <div class="sc_item" data-v-29e8c3c6=""><a target="_blank" href="https://twitter.com/Jesseakoh" data-v-29e8c3c6="">Twitter:       <img src="/img/logos_twitter.911e3d83.svg" alt="" data-v-29e8c3c6=""></a></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
export default {
  created() {
    AOS.init({
      easing: "ease-in-out",
      mirror: false,
      duration: 500,
    });
  },
};
</script>

<style lang="scss" scoped>
@mixin lte23 {
  display: flex;
  margin-top: 40px;
  justify-content: center;
  align-items: flex-end;
}
main {
  padding: 60px 0px;
  text-align: center;
  background: #121426;
  color: white;
  section {
    h3 {
      font-weight: normal;
      font-size: 1em;
    }
    h1 {
      font-size: 2.5em;
      margin: 10px 0px;
    }
    p {
      margin: 10px auto;
      text-align: center;
      width: 65%;
      font-weight: 300;
      @media (max-width: 700px) {
        width: 100%;
        margin: 20px auto;
      }
    }
    .cre_wmo {
      .first {
        @include lte23;
        .cc_de {
          width: 29%;
          .img {
            margin: 10px auto;
            width: 90%;
            img {
              width: 100%;
              border-radius: 10px;
            }
          }
          .id {
            margin-top: 10px;
            p {
              margin: 0px auto !important;
            }
          }
        }
        @media (max-width: 700px) {
          flex-direction: column;
          .cc_de {
            width: 100%;
          }
        }
      }
      .second {
        @include lte23;
        .cc_de {
          width: 35%;
          .img {
            margin: 10px auto;
            width: 90%;
            img {
              width: 100%;
              border-radius: 10px;
            }
          }
          .id {
            margin-top: 10px;
            p {
              margin: 0px auto !important;
            }
          }
        }
        @media (max-width: 700px) {
          flex-direction: column;
          .cc_de {
            width: 100%;
            padding: 5px;
          }
        }
      }
    }
  }
}
</style>
