<template>
	<main>
		<section id="faqs">
			<h1 data-aos="fade-in">FAQs</h1>
			<div class="gr_d">
				<div class="fq_wmo" data-aos="fade-in" >
					<h2>How much does a warrior mammoth cost to mint?</h2>
					<p>A warrior mammoth will cost <span>0.00</span> ETH to mint.</p>
				</div>
				<div class="fq_wmo" data-aos="fade-in" data-aos-delay="300">
					<h2>What is the total supply?</h2>
					<p>
						A total of <span>3,333</span> existential spirit of the warrior mammoth NFTs will be
						created
					</p>
				</div>
				<div class="fq_wmo" data-aos="fade-in" data-aos-delay="500">
					<h2>When is the launch?</h2>
					<p>March  25th 2022, 10pm UTC</p>
				</div>
				<div class="fq_wmo" data-aos="fade-in" data-aos-delay="700">
					<h2>Will there be a pre-sale</h2>
					<p>No there won’t be a presale</p>
				</div>
			</div>
		</section>
	</main>
</template>

<script>
import AOS from 'aos'
import "aos/dist/aos.css";
export default {
	created(){
		AOS.init({
			easing:"ease-in-out",
			mirror:false,
			duration: 1000
		})
	}
};
</script>

<style lang="scss" scoped>
main {
	padding: 60px 0px;
    background-image: url('../assets/12.svg'), url('../assets/11.svg');
    background-position: left 115%, left 120%;
    background-size: cover;
    background-repeat: no-repeat;
	@media (max-width:600px){
		background-image:none;
	}
    h1 {
			font-size: 2.5em;
			margin: 20px 0px;
            text-align: center;
		}
	.gr_d {
		display: grid;
		grid-row-gap: 30px;
		grid-template-columns: repeat(2, 1fr);
		@media (max-width:600px){
			text-align: center;
			grid-template-columns: repeat(1, 1fr);
		}
		.fq_wmo {
            
			h2 {
				font-family: "Grotesk bold";
                color: #42464c;

				margin: 10px 0px;
			}
			p {
				margin: 5px auto;
                span{
                    font-weight: bolder;
                    font-family: 'grotesk bold';
                }
			}
		}
	}
}
</style>
