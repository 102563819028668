<template>
	<main>
		<section id="wearables">
			<div class="wearables flex">
				<div class="wm_mo">
					<h1>Wearables</h1>
					<div class="img2">
						<img src="../assets/93.svg" alt="">
					</div>
					<div class="grid">
						<div class="grid_item" data-aos="fade-up" data-aos-delay="0">
							<h2>Outfit</h2>
							<p>
								Warriors are outfitted with a wide range of warrior and royal clothing
								across various tribes.
							</p>
						</div>
						<div class="grid_item" data-aos="fade-up" data-aos-delay="300">
							<h2>Tusk</h2>
							<p>With a wide range of tusk designs attributed.</p>
						</div>
						<div class="grid_item" data-aos="fade-up" data-aos-delay="600">
							<h2>Trunk</h2>
							<p>Trunks are stylized according to the Warriors mode.</p>
						</div>
						<div class="grid_item" data-aos="fade-up" data-aos-delay="900">
							<h2>Face</h2>
							<p>Angry, sad, beast mode.</p>
						</div>
						<div class="grid_item" data-aos="fade-up" data-aos-delay="1200">
							<h2>Weapon & Head gear</h2>
							<p>
								There are over 25 weapons and head gear associated with the warrior
								mammoth, to ensure each variations ctreate a unique identity for this
								spirit animal
							</p>
						</div>
						<div class="grid_item" data-aos="fade-up" data-aos-delay="1500">
							<h2>Ornaments</h2>
							<p>Various ornaments are worn by the warrior</p>
						</div>
					</div>
				</div>
				<div class="img">
					<img data-aos="fade-left" data-aos-once="false" src="../assets/572.png" alt="" />
				</div>
			</div>
		</section>
	</main>
</template>

<script>
import AOS from 'aos'
import "aos/dist/aos.css";
export default {
	created(){
		AOS.init({
			easing:"ease-in-out",
			mirror:false,
			duration: 1000
		})
	}
};
</script>

<style lang="scss" scoped>
main {
	padding: 50px 0px;
	background-color: #575757;
	color: white;
	background-image: url("../assets/90.svg");
	background-blend-mode: soft-light;
	background-repeat: no-repeat;
	background-size: cover;
	.wm_mo {
		width: 55%;
		margin: 20px 0px;
		h1 {
			font-size: 2.5em;
		}
		.img2{
			display: none;
		}
		.grid {
			width: 100%;
			display: grid;
			margin-top: 30px;
			grid-gap: 20px;
			grid-template-columns: repeat(2, 1fr);
			.grid_item {
				h2 {
					font-size: 1.5em;
				}
				p {
					margin: 20px 0px;
					line-height: 24px;
				}
			}
		}
	}
	.img {
		width: 42%;
		img {
			width: 100%;
		}
	}
}
@media (max-width:600px){
	main{
		background-image: url('../assets/94.svg');
		.wearables{
			flex-direction: column;
			.wm_mo{
				width: 100%;
				text-align: center;
				.img2{
					display: block;
					margin: 30px 0px;
				}
				.grid{
					grid-template-columns: repeat(1, 1fr);
					grid-gap: 30px;
					.grid_item{
						p{
							margin: 5px 0px;
						}
					}
				}
			}
			.img{
				display: none;
			}
		}
	}
}
</style>
