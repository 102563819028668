<template>
	<main>
		<section id="roadmap">
			<div class="flex">
				<div class="rd_wmo" data-aos="fade-in">
					<h3>Minting Begins</h3>
					<p>March  25th 2022, 10pm UTC</p>
				</div>
				<div class="txt_head">
					<h1>Roadmap</h1>
					<p>
						This roadmap outlines our goals and where we want to take the Warrior
						Mammoths as a community. The new world is constantly evolving, and our aim
						is to ensure we continue to be at the intersection of creativity and
						innovation at all times.
					</p>
				</div>
			</div>
			<div class="addit">
				<img src="../assets/589.svg" alt="" />
			</div>
			<div class="flex ump">
				<div class="rd_wmo" data-aos="fade-in">
					<h3>50% Minted</h3>
					<p>
						At 50% mint the community will be able to vote for a charity in which
						a certain donation will be made. This will be done to impact
						and elevate less priviledge kids in Africa & Asia. Our goal is to empower
						these kids by providing them with all the education & technological
						infrastucture to become super creators and world changers in the new
						digital world we are collectively building.
					</p>
				</div>
				<div class="rd_wmo" data-aos="fade-in" data-aos-delay="300">
					<h3>70% Minted</h3>
					<p>
						At 75% minted, we will airdrop an exclusive 2 of 2 Warrior Mammoths to two
						random owners.
					</p>
				</div>
				<div class="rd_wmo" data-aos="fade-in" data-aos-delay="500">
					<h3>100% Minted</h3>
					<p>
						At 100% minted, Owners with one or more Mammoth gets an exclusive
						membership minting access to the mammoth club. This allows you to be able
						to claim the future token drop stated only for warrior mammoth owners.
					</p>
				</div>
			</div>
		</section>
	</main>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
export default {
	created() {
		AOS.init({
			easing: "ease-in-out",
			mirror: false,
			duration: 1000
		});
	}
};
</script>

<style lang="scss" scoped>
main {
	padding: 100px 0px;
	background-color: #575757;
	color: white;
	background-image: url("../assets/90.svg");
	background-blend-mode: soft-light;
	background-repeat: no-repeat;
	background-size: cover;
	.addit {
		margin: 0 auto;
		width: 80%;
		position: relative;
		top: -50px;
		img {
			width: 100%;
		}
		@media (max-width: 760px) {
			display: none;
		}
	}
	.flex {
		align-items: flex-start;
		.txt_head {
			width: 40%;
			h1 {
				font-size: 2.5em;
				margin-bottom: 20px;
			}
		}
		.rd_wmo {
			h3 {
				margin: 10px 0px;
			}
			width: 30%;
			background: #dbd4ff;
			color: black;
			border-radius: 8px;
			text-align: center;
			padding: 15px 20px;
		}
	}
}
@media (max-width: 760px) {
	main {
		background-image: url("../assets/Rectangle 94.svg");
		padding: 60px 0px;
		.flex {
			&:nth-child(1) {
				flex-direction: column-reverse;
				.txt_head,
				.rd_wmo {
					width: 100%;
					margin: 20px 0px;
				}
			}
		}
		.ump {
			flex-direction: column;
			.rd_wmo {
				width: 100%;
				margin: 20px 0px;
			}
		}
	}
}
</style>
